//@ts-ignore
import App from "./App.vue";
import Vue from "vue";
import router from "@/router/router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import { VNode } from "vue/types/umd";
import * as VueGoogleMaps from "vue2-google-maps";
import "./plugins/vue-currency-input";
import "./assets/styles/global.scss";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBNV1H4Rcb3MJsK_oPy8icg21isAnGfrqk",
  },
});

Vue.use(VueCookies);
Vue.$cookies.config('30d')
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h): VNode => h(App),
}).$mount("#app");

window.addEventListener("showMaintenanceMessage", () => {
  const petaolado = "PetAoLado.com";
  const message = "Ops, estamos em manutenção!";
  const subMessage = "Por favor, tente novamente mais tarde.";

  const maintenanceDiv = document.createElement("div");
  maintenanceDiv.style.position = "fixed";
  maintenanceDiv.style.top = "0";
  maintenanceDiv.style.left = "0";
  maintenanceDiv.style.width = "100%";
  maintenanceDiv.style.height = "100%";
  maintenanceDiv.style.backgroundColor = "rgba(0, 0, 0, 0.4)";
  maintenanceDiv.style.color = "white";
  maintenanceDiv.style.display = "flex";
  maintenanceDiv.style.flexDirection = "column";
  maintenanceDiv.style.justifyContent = "center";
  maintenanceDiv.style.alignItems = "center";
  maintenanceDiv.style.zIndex = "10000";

  maintenanceDiv.innerHTML = `
    <div style="font-family: fredoka; font-size: 45px; font-weight: bold; margin-bottom: 20px;">
      ${petaolado}
    </div>
    <div style="font-family: fredoka; font-size: 30px; margin-bottom: 10px;">
      ${message}
    </div>
    <div style="font-family: fredoka; font-size: 25px;">
      ${subMessage}
    </div>
  `;

  document.body.appendChild(maintenanceDiv);
});

const EventBus = new Vue();
export default EventBus;